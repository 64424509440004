@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'daisyui/dist/full.css';
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

.font-figtree {
    @import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;700&display=swap');

}

.hero-background {
    background-image: url('../public/mtech-bg.png');
    /* Other background properties */
}

/* App.css or your custom CSS file */
.hero-overlay.blur {
    backdrop-filter: blur(8px); /* Adjust the blur amount as needed */
}