/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Product Container */
.product-container {
  background-color: #fff; /* Clean white background */
  border-radius: 16px;
  padding: 1rem;
  max-width: 500px;
  margin: 0 auto;
  border: 2px solid #f9e300; /* Solid yellow border */
  
  /* Neon glow effect */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 
              0 0 15px #f9e300,   /* Neon yellow outer glow */
              0 0 30px #f9e300,   /* Larger neon yellow glow */
              0 0 45px #f9e300;   /* Even larger glow */
  
  transition: all 0.3s ease-in-out;
}

.product-container:hover {
  transform: translateY(-8px); /* Slight lift on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
              0 0 20px #f9e300,   /* Enhance the glow on hover */
              0 0 40px #f9e300,
              0 0 60px #f9e300;
}

/* Product Image */
.product-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 1.5rem;
}

.product-image {
  max-width: 100%;
  transition: transform 0.5s ease-in-out, box-shadow 0.5s;
}

.product-image:hover {
  transform: scale(1.1);  /* Larger hover effect */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Product Info */
.product-info h1 {
  font-size: 2.5rem;
  color: #ebebeb;
  font-weight: bold;
  margin-bottom: 1rem;
}

.product-description {
  font-size: 1.2rem;
  color: #777;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.product-price {
  font-size: 2rem;
  color: #4CAF50;
  font-weight: 600;
  margin-bottom: 2rem;
}

/* Add to Cart Button */
.product-action button {
  background-color: #1D72B8;  /* Fresh blue button */
  color: #fff;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  border-radius: 30px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  border: none;
  cursor: pointer;
}

.product-action button:hover {
  background-color: #155a8a;
  transform: scale(1.05);  /* Slight scaling on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .product-container {
    padding: 1.5rem;
  }

  .product-info h1 {
    font-size: 2rem;
  }

  .product-price {
    font-size: 1.8rem;
  }

  .product-action button {
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
  }
}
