@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'daisyui/dist/full.css';
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
/* styles.css */

/* Ensure the transform-origin is at the top for collapsing upwards */
.collapse,
.expand {
  transform-origin: top;
}

.collapse {
  transform: scaleY(0);
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.expand {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;
}
